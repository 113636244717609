import classNames from 'classnames'
import { ElementType } from 'react'

import { Box } from '../Box'

import { Size, WrapperProps } from './types'

const sizeClasses: { [key in Size]: string } = {
  sm: 'wrapper--size-sm',
  md: 'wrapper--size-md',
  full: 'wrapper--size-full',
}

const defaultElement = 'div'

export const Wrapper = <C extends ElementType = typeof defaultElement>({
  children,
  as,
  size = 'md',
  className,
  ...props
}: WrapperProps<C>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames('wrapper', sizeClasses[size], className)}
      {...props}
    >
      {children}
    </Box>
  )
}
