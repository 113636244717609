import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from 'react'

import { useDisclosure, useKeyPress, useLockedBody } from '@lounge-fe/ui-kit'

import { SearchProviderProps, SearchProviderState } from './types'
import { useSearchParams } from '@/hooks/useSearchParams'

const SearchContext = createContext<SearchProviderState>({
  onSubmit: () => {},
  onClear: () => {},
  search: '',
  setSearch: () => {},
})

export const useSearchContext = () => useContext(SearchContext)

export const SearchProvider = ({
  children,
  onSubmit: parentOnSubmit,
  submitDebounceDelay = 500,
}: SearchProviderProps) => {
  const { searchParams, replace } = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('q') ?? '')
  const [, setLockedBody] = useLockedBody(false)
  const debounceTimer = useRef<null | number>(null)
  const isEscapePressed = useKeyPress('Escape')
  const hasChanged = useRef(false)

  useEffect(() => {
    if (search) {
      hasChanged.current = true
      replace({ q: search })
    } else {
      replace({ q: undefined })
    }
  }, [search])

  const onSubmit = useCallback(
    (value: string) => {
      if (debounceTimer.current) {
        window.clearTimeout(debounceTimer.current)
      }
      parentOnSubmit(value)
    },
    [parentOnSubmit]
  )

  const onClear = useCallback(() => {
    setSearch('')
  }, [])

  useEffect(() => {
    if (!hasChanged.current) {
      return
    }

    debounceTimer.current = window.setTimeout(
      () => onSubmit(search),
      submitDebounceDelay
    )

    return () => {
      debounceTimer.current && window.clearTimeout(debounceTimer.current)
    }
  }, [search, onSubmit, submitDebounceDelay])

  useEffect(() => {
    if (search && isEscapePressed) {
      onClear()
    }
  }, [isEscapePressed, search, onClear])

  const context = useMemo<SearchProviderState>(
    () => ({
      onClear,
      search,
      setSearch,
      onSubmit,
    }),
    [onClear, search]
  )

  return (
    <SearchContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </SearchContext.Provider>
  )
}
