import { ProductCard, Text } from '@lounge-fe/ui-kit'
import { CollectionProductsProps } from './types'

export const CollectionProducts = ({ products }: CollectionProductsProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-6">
      {products.map((product) => (
        <ProductCard key={product.id} href={product.url}>
          <ProductCard.Image
            primarySrc={
              product.images[0]
                ? {
                    src: product.images[0].src,
                    width: 398,
                    height: 557,
                    crop: 'center',
                  }
                : undefined
            }
            secondarySrc={
              product.images[1]
                ? {
                    src: product.images[1].src,
                    width: 398,
                    height: 557,
                    crop: 'center',
                  }
                : undefined
            }
            alt={product.images[0]?.alt}
          />
          <Text className="font-semi-bold mt-4">{product.title}</Text>
        </ProductCard>
      ))}
    </div>
  )
}
