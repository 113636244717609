import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Search: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 10.5C19.6 15.5258 15.5258 19.6 10.5 19.6C5.47421 19.6 1.4 15.5258 1.4 10.5C1.4 5.47421 5.47421 1.4 10.5 1.4C15.5258 1.4 19.6 5.47421 19.6 10.5ZM17.4222 18.3953C15.5741 20.0169 13.1519 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 13.1519 20.0169 15.5741 18.3953 17.4222C18.4304 17.4462 18.4638 17.4739 18.495 17.505L23.495 22.505C23.7683 22.7784 23.7683 23.2216 23.495 23.495C23.2216 23.7683 22.7784 23.7683 22.505 23.495L17.505 18.495C17.4739 18.4638 17.4462 18.4304 17.4222 18.3953Z"
      />
    </Icon>
  )
}
