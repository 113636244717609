import { ActionSheet } from '@lounge-fe/ui-kit/src/components/ActionSheet'
import { Hotspot, Image, Link, Text } from '@lounge-fe/ui-kit'

import { Money } from '@shopify/hydrogen-react'
import { useShopify } from '@/hooks/useShopify'
import { ProductActionSheetProps } from './types'

export const ProductActionSheet: React.FC<ProductActionSheetProps> = ({
  hotspot,
  idx,
  actionSheetText,
  actionSheetButtonText,
}) => {
  const { currency } = useShopify()

  return (
    <ActionSheet>
      <ActionSheet.Trigger>
        <Hotspot
          className="hotspot"
          style={{
            top: hotspot.yPosition + '%',
            left: hotspot.xPosition + '%',
          }}
        />
      </ActionSheet.Trigger>
      <ActionSheet.Content>
        <ActionSheet.Header className="pb-0 border-b-0">
          <Text className="font-semi-bold" color="secondary">
            {actionSheetText}
          </Text>
        </ActionSheet.Header>
        <ActionSheet.Body className="pb-12">
          <div className="flex flex-row gap-[12px]">
            <div>
              <Image
                src={hotspot.product.images[0].src}
                height={300}
                width={300}
                alt=""
                loading="lazy"
                key={idx}
                className="object-cover rounded-sm h-full max-w-[84px]"
              ></Image>
            </div>
            <div>
              <Text variant="body-sm" className="pb-1">
                {hotspot.product.title} -
                <span className="text-[#7D7D7D]"> {hotspot.product.color}</span>
              </Text>
              <div className="flex flex-row gap-2">
                {hotspot.product.compare_at_price ? (
                  <Text
                    variant="body-sm"
                    className="pb-3 line-through text-[#c8c8c8]"
                  >
                    <Money
                      data={{
                        amount: (
                          hotspot.product.compare_at_price / 100
                        ).toString(),
                        currencyCode: currency.active,
                      }}
                      as="span"
                    />
                  </Text>
                ) : (
                  ''
                )}
                <Text variant="body-sm" className="font-semi-bold pb-3">
                  <Money
                    data={{
                      amount: (hotspot.product.price / 100).toString(),
                      currencyCode: currency.active,
                    }}
                    as="span"
                  />
                </Text>
              </div>

              <Link className="underline" href={hotspot.product.url}>
                {actionSheetButtonText}
              </Link>
            </div>
          </div>
        </ActionSheet.Body>
      </ActionSheet.Content>
    </ActionSheet>
  )
}
