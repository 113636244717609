import classNames from 'classnames'
import { forwardRef, useCallback } from 'react'
import { AnimatePresence, motion, PanInfo } from 'framer-motion'

import { Popover, usePopoverContext } from '../Popover'
import { Content as PopoverContent } from '../Popover/Content'
import { Portal } from '../Portal'
import { Transition } from '../Transition'

import { ActionSheetProps } from './types'

export const Content: React.FC<ActionSheetProps> = forwardRef<
  HTMLDivElement,
  ActionSheetProps
>(({ children, className, dismissDragOffset = 100, ...props }, ref) => {
  const { isOpen, onClose } = usePopoverContext()

  const onDragEnd = useCallback(
    (evt: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      if (info.offset.y > dismissDragOffset) {
        onClose()
        evt.stopPropagation()
      }
    },
    [onClose]
  )

  return (
    <Portal>
      <Popover.Backdrop className="z-50" />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: '0%' }}
            exit={{ y: '100%' }}
            dragConstraints={{ top: 0, bottom: 0 }}
            dragElastic={{ top: 0, bottom: 1 }}
            dragMomentum={false}
            transition={{
              duration: 0.33,
            }}
            onDragEnd={onDragEnd}
            drag="y"
            className="fixed inset-0 top-10 bottom-0 z-[60] flex items-end justify-center pointer-events-none"
          >
            <PopoverContent
              className={classNames(
                'flex flex-col bg-white max-h-[calc(100vh_-_56px)] w-full pointer-events-auto relative rounded-t-md',
                className
              )}
              ref={ref}
              {...props}
            >
              {children}
            </PopoverContent>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  )
})

Content.displayName = 'ActionSheetContent'
