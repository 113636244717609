import { useOneTrust } from '@/hooks/useOneTrust'
import { Link } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'
import { PolicyMenuProps } from './types'

export const PolicyMenu = ({ menu, ...props }: PolicyMenuProps) => {
  const { OpenSettings } = useOneTrust()
  const { t } = useTranslation()
  return (
    <nav {...props}>
      <ul className="flex flex-wrap gap-y-4 leading-[1]">
        {menu.map((item, idx) => (
          <li
            key={idx}
            className="border-r last:border-0 border-[#282828] block pr-3 mr-3 lg:pr-1 lg:mr-1 relative"
          >
            <Link
              variant="body-sm"
              className="text-[#7D7D7D] !font-regular hover:text-white"
              href={item.url}
            >
              {item.title}
            </Link>
          </li>
        ))}
        <li
          onClick={OpenSettings}
          className="border-r last:border-0 border-[#282828] block pr-3 mr-3 lg:pr-1 lg:mr-1 relative"
        >
          <span className="typography typography--body-sm cursor-pointer text-[#7D7D7D] !font-regular hover:text-white">
            {t('account.cookie_settings')}
          </span>
        </li>
      </ul>
    </nav>
  )
}
