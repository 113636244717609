import { Search } from '../Search'
import { useSearchContext } from '../Navigation/SearchProvider'
import { RecommendationProps } from './types'

export const Recommendations = ({
  suggestedSearchTerms,
}: RecommendationProps) => {
  const { setSearch } = useSearchContext()
  return (
    <>
      {suggestedSearchTerms?.length &&
        suggestedSearchTerms.map((term) => {
          return (
            <Search.MenuItem
              key={term}
              onClick={() => setSearch(term.toLowerCase())}
            >
              {term}
            </Search.MenuItem>
          )
        })}
    </>
  )
}
