import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Account: React.FC<SvgProps> = (props) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24" {...props}>
      <circle
        cx="12"
        cy="6"
        r="5.3"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.4"
      />
      <path
        d="M0.7 21.2778C0.7 17.3688 3.86883 14.2 7.77778 14.2H16.2222C20.1312 14.2 23.3 17.3688 23.3 21.2778C23.3 22.3946 22.3946 23.3 21.2778 23.3H2.72222C1.60538 23.3 0.7 22.3946 0.7 21.2778Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.4"
      />
    </Icon>
  )
}
