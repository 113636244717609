import classNames from 'classnames'
import { useMemo } from 'react'

import { Text } from '../../components'
import { Skeleton } from '../../components/Skeleton'
import { hexToHSL } from '../../utilities'
import { normaliseProductColor } from '../../utilities/normaliseProductColor'

import { Swatch, SwatchesProps } from './types'
import { useProductCardContext } from './ProductCard'

const lightColorDiscriminator = hexToHSL('#e9e9e9')

const calculateGradient = (hexes: string[] = []) =>
  hexes
    .map(
      (hex, index) =>
        `${hex} ${(index * 100) / hexes.length}%, ${hex} ${
          ((index + 1) * 100) / hexes.length
        }%`
    )
    .join(', ')

const calculateIsLightColor = (hexes: string[] = []) =>
  hexes.some((hex) => {
    const hsl = hexToHSL(hex)
    return (
      hsl.lightness > lightColorDiscriminator.lightness && hsl.saturation < 20
    )
  })

export const Swatches = ({
  swatches = [],
  isLoading = false,
  selectedSwatchId,
  onSwatchSelect,
  availableColors = {},
  canonicalColors = {},
  limit = 4,
}: SwatchesProps) => {
  const { href } = useProductCardContext()

  const validSwatches = useMemo(() => {
    const getHexes = (swatch: Swatch) => {
      let hexes: string[] = []

      if (swatch.color && availableColors) {
        const normalised = normaliseProductColor(swatch.color)
        hexes = availableColors[normalised]
      }

      if (swatch.canonical_color && !hexes?.length) {
        const normalised = normaliseProductColor(swatch.canonical_color)
        hexes = [canonicalColors[normalised]]
      }

      // Fallback if no color or canonical_color is available
      if (!hexes?.length) {
        hexes = []
      }

      return {
        ...swatch,
        hexes,
        isLightColor: calculateIsLightColor(hexes),
        background:
          hexes.length > 1
            ? `linear-gradient(45deg, ${calculateGradient(hexes)})`
            : hexes[0],
      }
    }

    return swatches.map(getHexes).filter((swatch) => swatch.hexes?.length)
  }, [swatches])

  const slicedSwatches = validSwatches.slice(0, limit)
  const remaining = validSwatches.length - limit

  if (isLoading) {
    return (
      <Skeleton className="flex h-6 gap-x-2 items-center">
        <Skeleton.Block className="w-4 h-4 rounded-circle" />
        <Skeleton.Block className="w-4 h-4 rounded-circle" />
        <Skeleton.Block className="w-4 h-4 rounded-circle" />
      </Skeleton>
    )
  }
  return (
    <div className="flex h-6 -ml-1 items-center">
      {slicedSwatches.map((swatch) => {
        return (
          <button
            key={swatch.id}
            type="button"
            onClick={() => onSwatchSelect(swatch.id)}
            className="px-1 h-full"
            title={swatch.color}
          >
            <span
              className={classNames(
                'block relative w-4 h-4 rounded-circle overflow-hidden',
                swatch.id === selectedSwatchId &&
                  'shadow-[0_0_0_1px_#b5b5b5_inset,0_0_0_2px_#fff_inset]',
                swatch.isLightColor &&
                  swatch.id !== selectedSwatchId &&
                  'shadow-[0_0_0_1px_#e9e9e9_inset]'
              )}
              style={{
                background: swatch.background,
              }}
            />
          </button>
        )
      })}
      {remaining > 0 && (
        <Text
          as="a"
          href={href}
          variant="body-sm"
          className="!leading-[1] hover:underline mx-1"
        >
          +{remaining}
        </Text>
      )}
    </div>
  )
}
