import useEmblaCarousel from 'embla-carousel-react'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Carousel, CaptionImage, Image, Wrapper } from '@lounge-fe/ui-kit'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

import { ImageData } from './types'
import { theme } from '@lounge-fe/tailwind'
import classNames from 'classnames'

export const CaptionedImage: ShopifySectionComponent<ImageData> = ({
  data,
}) => {
  const { images } = data!

  const validImages = images.filter(
    (image) => image.image || image.mobile_image
  )

  const isCarouselActive = validImages.length > 4

  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'center',
      containScroll: 'trimSnaps',
      skipSnaps: true,
      active: true,
      breakpoints: {
        [`(min-width: ${theme.screens.xl})`]: {
          active: isCarouselActive,
        },
      },
      loop: true,
    },
    [WheelGesturesPlugin()]
  )

  return (
    <div className="overflow-hidden">
      <Carousel emblaRef={emblaRef} embla={embla}>
        <Carousel.Viewport
          className={classNames(
            'wrapper m-auto',
            isCarouselActive && 'wrapper--size-full'
          )}
        >
          <Carousel.Container
            className={classNames(
              'captioned-image__grid',
              validImages.length === 3 && 'xl:auto-cols-[calc(33.33%_-_10px)]'
            )}
          >
            {validImages.map((images, idx) => {
              const { image, mobile_image, caption, url } = images
              const defaultImage = mobile_image || image
              const defaultLgImage = image || mobile_image
              const as = url ? 'a' : 'div'

              return (
                <Carousel.Slide key={idx} index={idx} className="">
                  <CaptionImage>
                    <CaptionImage.Container as={as} href={url}>
                      <Image
                        className="captioned-image__image"
                        data={{
                          src: defaultImage.src,
                          width: 350,
                          height: 350,
                          quality: 90,
                          crop: 'center',
                        }}
                        role={!defaultImage.alt ? 'presentation' : undefined}
                        alt={defaultImage.alt}
                      >
                        <Image.Source
                          media="(min-width: 851px)"
                          data={{
                            src: defaultLgImage.src,
                            width: 350,
                            height: 350,
                            quality: 90,
                            crop: 'center',
                          }}
                        />
                      </Image>
                      <CaptionImage.Caption caption={caption} />
                    </CaptionImage.Container>
                  </CaptionImage>
                </Carousel.Slide>
              )
            })}
          </Carousel.Container>
        </Carousel.Viewport>
        {validImages.length > 4 && (
          <Wrapper>
            <hr className="hidden md:block mt-8 pb-2" />
          </Wrapper>
        )}
      </Carousel>
    </div>
  )
}
