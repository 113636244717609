import { useTranslation } from 'react-i18next'
import {
  Box,
  Flex,
  Popover,
  Heading,
  Icon,
  IconButton,
  CloseIconButton,
  Transition,
} from '@lounge-fe/ui-kit'
import { AccountMenuContent } from '../AccountMenuContent'

export const AccountMenu = () => {
  const { t } = useTranslation()
  return (
    <Popover>
      {({ onClose, isOpen }) => (
        <>
          <Popover.Trigger>
            <IconButton
              type="button"
              icon={<Icon.Account width={24} height={24} />}
              size="lg"
              isActive={isOpen}
              aria-label={t('account.account')}
            />
          </Popover.Trigger>

          <div className="absolute overflow-hidden right-0 top-[var(--header-height)] px-6 pb-6 desktop-navigation__account-menu">
            <Transition.Slide
              direction="top"
              data-testid="account-menu-transition"
            >
              <Popover.Content
                className="p-6 min-w-[322px] rounded-b-lg bg-white shadow-3"
                onClickAway={onClose}
              >
                <Box className="relative">
                  <Flex className="items-center">
                    <Flex className="justify-center flex-1">
                      <Heading variant="title-six" className="uppercase">
                        {t('account.my_account')}
                      </Heading>
                    </Flex>
                    <CloseIconButton
                      aria-label={t('action.close')}
                      onClick={onClose}
                    />
                  </Flex>
                  <AccountMenuContent />
                </Box>
              </Popover.Content>
            </Transition.Slide>
          </div>
        </>
      )}
    </Popover>
  )
}
