import { useTranslation } from 'react-i18next'

import { Text } from '../Text'

import { RejectionsProps } from './types'

export const Rejections = ({ rejections }: RejectionsProps) => {
  const { t } = useTranslation()
  return (
    <div className="space-y-4">
      {rejections.map((rejection) => (
        <div key={rejection.filename}>
          {rejections.length > 1 && (
            <Text className="font-semi-bold">{rejection.filename}</Text>
          )}
          <ul>
            {rejection.errors.map((error) => (
              <Text key={error} as="li" variant="body-sm">
                {error}
              </Text>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
