import { Heading, Text } from '../../components'

import { DetailsProps } from './types'

export const Details: React.FC<DetailsProps> = ({
  category,
  title,
  date,
  readTime,
}) => (
  <div className="flex flex-col gap-y-2">
    {readTime && date && (
      <Text variant="body-sm" className="text-action-primary-hovered">
        {readTime} | {date}
      </Text>
    )}
    <Heading variant="title-five">{title}</Heading>
    {category && (
      <div className="w-fit bg-background-secondary-default px-6 py-3">
        <Text className="text-[10px] font-semi-bold uppercase">{category}</Text>
      </div>
    )}
  </div>
)
