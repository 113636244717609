import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { create } from 'zustand'

export const useSearchParamsStore = create<{
  searchParams: URLSearchParams
  setSearchParams: (newState: URLSearchParams) => void
}>((set) => ({
  searchParams: new URLSearchParams(window.location.search),
  setSearchParams: (searchParams: URLSearchParams) => set({ searchParams }),
}))

export const useSearchParams = () => {
  const { searchParams, setSearchParams } = useSearchParamsStore(
    (state) => state
  )

  const applyNewParams = useCallback(
    <T extends Record<string, unknown>>(
      currentParams: URLSearchParams,
      updates: T
    ) => {
      const newParams = new URLSearchParams(currentParams)
      Object.entries(updates).forEach(([key, value]) => {
        if (value === undefined) {
          newParams.delete(key)
        } else {
          newParams.set(key, String(value))
        }
      })
      return newParams
    },
    []
  )

  const push = useCallback(
    <T extends Record<string, unknown>>(updates: T) => {
      const newParams = applyNewParams(searchParams, updates)
      window.history.pushState(
        {},
        '',
        newParams.size === 0
          ? window.location.pathname
          : `${window.location.pathname}?${newParams.toString()}`
      )
      setSearchParams(newParams)
    },
    [searchParams, applyNewParams]
  )

  const replace = useCallback(
    <T extends Record<string, unknown>>(updates: T) => {
      const newParams = applyNewParams(searchParams, updates)

      window.history.replaceState(
        {},
        '',
        newParams.size === 0
          ? window.location.pathname
          : `${window.location.pathname}?${newParams.toString()}`
      )
      setSearchParams(newParams)
    },
    [searchParams, applyNewParams]
  )

  useEffect(() => {
    const handler = () =>
      setSearchParams(
        applyNewParams(new URLSearchParams(window.location.search), {})
      )

    window.addEventListener('popstate', handler)
    return () => {
      window.removeEventListener('popstate', handler)
    }
  }, [applyNewParams])

  return { searchParams, push, replace }
}
