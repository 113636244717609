import { Box, Text } from '@lounge-fe/ui-kit'
import { Children, cloneElement } from 'react'

import { SubMenuProps } from './types'

export const SubMenu = ({ title, children, tabIndex }: SubMenuProps) => (
  <Box role="group">
    {title && (
      <Text variant="body-md" className="mb-1 uppercase font-semi-bold">
        {title}
      </Text>
    )}
    <Box as="ul" className="flex flex-col p-0 m-0 list-none">
      {Children.map(children, (child) => cloneElement(child, { tabIndex }))}
    </Box>
  </Box>
)
