import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ArrowLeft: React.FC<SvgProps> = (props) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5 11.4768C24.7761 11.4768 25 11.7007 25 11.9768C25 12.2529 24.7761 12.4768 24.5 12.4768L2.53725 12.4768L7.66958 17.3768C7.86931 17.5675 7.87665 17.884 7.68596 18.0837C7.49527 18.2834 7.17877 18.2908 6.97904 18.1001L1.01937 12.4102C0.875639 12.273 0.831543 12.0706 0.889173 11.8935C0.831543 11.7164 0.875639 11.514 1.01937 11.3768L6.97904 5.68694C7.17877 5.49625 7.49527 5.50358 7.68596 5.70331C7.87665 5.90304 7.86931 6.21954 7.66958 6.41023L2.36274 11.4768L24.5 11.4768Z"
        fill="currentColor"
      />
    </Icon>
  )
}
